//Styling
$enable-rounded: false;
$enable-negative-margins: true;

//Colours
$scoutpurple: #7413dc; //For some reason the corporate site uses the darker purple!?
//$scoutpurple: #490499;
$scoutteal: #00b8a3;
$scoutred: #ed3f23;
$scoutpink: #ffb4e5;
$scoutgreen: #25b755;
$scoutnavy: #003982;
$scoutblue: #006ddf;
$scoutyellow: #ffe627;

//Set colour overrides
$purple: $scoutpurple;
$teal: $scoutteal;
$red: $scoutred;
$pink: $scoutpink;
$green: $scoutgreen;
$blue: $scoutblue;
$yellow: $scoutyellow;

$primary: $scoutpurple;
$secondary: $scoutteal;
$info: $scoutblue;
$success: $scoutgreen;
$danger: $scoutred;
$warn: $scoutyellow;
$light: #f1f1f1;
$dark: #000;

$body-color: #1f1f1f;
$link-color: #0061CE;
$link-decoration: null;
$link-hover-decoration: underline;

$border-width: 2px;
$border-color: #1f1f1f;
$input-border-color: #1f1f1f;
$input-color: #1f1f1f;
$input-group-addon-bg: $light;

// Typography
$font-family-base: "Nunito Sans";
$headings-font-weight: 900;

// Navbar Styling
$navbar-brand-padding-y: 1rem;

// Button Styling
$btn-font-weight: 700;
$btn-padding-x: .75rem;
$btn-padding-y: .25rem;
$btn-line-height: 1.4;

.text-gray {
  color: #495057;
}

// Colours to compute for logo
$logoColours: (
  "purple": $scoutpurple,
  "black": #000,
  "white": #fff,
  "green": $scoutgreen,
  "blue": $scoutblue,
  "red": $scoutred
);

// Sizes to compute for logo
$logoSizes: (
  "150": 150px,
  "200": 200px,
  "250": 250px,
  "300": 300px,
  "350": 350px,
  "400": 400px,
  "450": 450px,
  "500": 500px,
  "550": 550px,
  "600": 600px
);

$input-group-addon-font-weight: 700;

html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 300px !important; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.signin {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.signin-banner {
  background-image: url('/assets/img/login-banner.jpg');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-transparent {
  color: $purple !important;

  &:hover {
    background-color: $light;
    border-color: $light;
  }
}

.signin-logo {
  margin: 0 !important;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.highlight {
  background-color: rgba($primary, 0.3);
}

.card-clickable {
  border: 2px solid rgba(0, 0, 0, 0) !important;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.125) !important;
    transform: scale(1.02);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.link {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}

.fw-900 {
  font-weight: 900
}

;

.navbar-nav.navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
}

.bg-Unregistered {
  background-color: $yellow;
  color: $dark !important;
}

.bg-Registered {
  background-color: $blue;
}

.bg-Running {
  background-color: $blue;
}

.bg-Checked-In {
  background-color: $green;
}

.bg-Discarded {
  background-color: $dark;
}

.bg-Disqualified {
  background-color: $red;
}

.widegame-logo {
  color: #fff;
  background-color: #000;
  padding: 4px 10px;
  font-weight: 900;
  //font-size: 21px;
  margin-right: 7px;
}

.main-nav {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  box-sizing: border-box;

  .nav-link {
    color: $body-color;
    padding: 1.5rem 0;
    margin: 0 0.8rem -1px;

    &.active {
      color: $primary;
      border-bottom: 1px $primary solid;
    }

    &:hover {
      color: $body-color;
      border-bottom: 1px solid $body-color;
    }

    &:focus {
      color: $primary;
      border-bottom: 1px $primary solid;
    }
  }
}

.registration-tabs {
  .nav-link {
    color: $body-color !important;

    &.active {
      background-color: rgba(0, 0, 0, 0) !important;
      border-color: #dee2e6 #dee2e6 $light !important;
      font-weight: 900;
    }
  }
}

.card-team-type {
  cursor: pointer;
  border: solid 2px #FFF !important;

  &:hover {
    border-color: $body-color !important;
  }

  &.active {
    color: $primary !important;
    border-color: $primary !important;
  }
}

.scan-logo {
  font-size: 60px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.card-member {
  min-width: 8rem !important;
}

.scrolling-wrapper {
  overflow-x: auto;
}
